/* Add application styles & imports to this file! */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';


.g-bubble-container {
  display: flex;
  gap: 4px;
}

.g-bubble {
  background-color: #919191;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.g-bubble:hover {
  background-color: #b6b6b6;
  color: white;
}

.g-bubble__active {
  background-color: #6193a1;
  color: white;
}

/* .g-bubble__disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
  color: 919191;
} */

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-paginator {
  background: transparent !important;
}

.mat-mdc-paginator-range-actions {
  margin-right: 12px !important;
}
